import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Home from './components/Home.vue'
import About from './components/About.vue'
import Product from './components/Product.vue'
import Contact from './components/Contact.vue'
import DetailProduct from './components/DetailProduct.vue'
import ProductConventional from './components/ProductConventional.vue'
Vue.config.productionTip = false
Vue.use(VueRouter)
const router = new VueRouter({
  routes:[
    {
      path:'/',
      component:Home
    },
    {
      path:'/about',
      component:About
    },
    {
      path:'/product',
      component:Product
    },
    {
      path:'/contact',
      component:Contact
    },
    {
      path:'/product-detail',
      component:DetailProduct
    },
    {
      path:'/product/conventional',
      component:ProductConventional
    },
  ]
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
