<template>
  <div>
    <nav
      id="navbarExample"
      class="navbar navbar-expand-lg fixed-top navbar-light"
      aria-label="Main navigation"
    >
      <div class="container">
        <a class="navbar-brand logo-text" href="#">Residence</a>
        <button
          class="navbar-toggler p-0 border-0"
          type="button"
          id="navbarSideCollapse"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="navbar-collapse offcanvas-collapse"
          id="navbarsExampleDefault"
        >
          <ul class="navbar-nav ms-auto navbar-nav-scroll">
            <li class="nav-item">
              <a href="/" class="nav-link">Home</a>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link">Tentang</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/product" class="nav-link">Produk</router-link>
            </li>

            <li class="nav-item">
              <router-link to="/contact" class="nav-link">Kontak</router-link>
            </li>
          </ul>
          <span class="nav-item">
            <a class="btn-solid-sm">Hubungi 083100009999</a>
          </span>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {};
</script>