<template>
    <div>
      <section class="default p-t-10">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 mb-5">
              <iframe
                    height="350px"
                    width="100%"
                    class="responsive-iframe border border-4"
                    allowfullscreen="true"
                    src="https://momento360.com/e/u/e69afafee38e452fbc05f6a765b016f6?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true"
                  >
                </iframe>
            </div>
            <div class="col-lg-8 px-3">
              <div class="mb-5">
                <h4>Conventional</h4>
                <p class="p-large">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
              </div>
              <div>
                <div class="row">
                  <div class="col-lg-3 text-center">
                    <i class="fas fa-expand-arrows-alt"></i>
                    <p>Lebar</p>
                  </div>
                  <div class="col-lg-3 text-center">
                    <i class="fas fa-tools"></i>
                    <p>Bahan</p>
                  </div>
                  <div class="col-lg-3 text-center">
                    <i class="fas fa-calendar-alt"></i>
                    <p>Waktu</p>
                  </div>
                  <div class="col-lg-3 text-center">
                    <i class="fas fa-house-user"></i>
                    <p>Ruangan</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 px-3">
              <div class="contactus">
                <!--  -->
                <div class="text-container">
                  <p class="p-large">Dapatkan penawaran khusus, hubungi kami untuk mengetahui lebih lanjut mengenai ketersedian unit.</p>

                  <form action="" class="form-contactus">
                    <div class="form-group">
                      <span class="form-label">Nama</span>
                      <input class="form-control" type="text">
                    </div>

                    <div class="form-group">
                      <span class="form-label">E-Mail</span>
                      <input class="form-control" type="text">
                    </div>

                    <div class="form-group">
                      <span class="form-label">Nomor HP</span>
                      <input class="form-control" type="text">
                    </div>

                    <div class="form-group">
                      <input type="checkbox" class="form-check-input" id="check">
                      <label class="form-check-label" for="check">&nbsp; Saya setuju dengan ini.</label>
                    </div>

                    <div class="form-btn">
                      <button class="submit-btn">KIRIM <i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
<script>
  export default {};
</script>