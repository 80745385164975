<template>
  <div>
    <div id="product" class="cards-1 bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Virtual Tour Unit</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <div class="card">
              <div class="card-header">
                <iframe
                  height="350px"
                  width="1000px"
                  class="responsive-iframe"
                  allowfullscreen="true"
                  src="https://momento360.com/e/u/e69afafee38e452fbc05f6a765b016f6?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true"
                >
                </iframe>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h5 class="card-title">Griya tawang</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </p>
              </div>
              <div class="col-lg-6">
                <div class="card">
                  <div class="card-header">
                    <h5>Tim Kami Siap Membantu</h5>
                  </div>
                  <div class="card-body">
                    <p>
                      Isi form berikut, marketing kami akan segera mengirimkan
                      informasi, harga, dan brosur.
                    </p>
                    <form>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control-input"
                          required
                          placeholder="Nama lengkap"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control-input"
                          required
                          placeholder="Email"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control-input"
                          required
                          placeholder="Nomor Telepon"
                        />
                      </div>
                      <div class="form-group">
                        <button
                          type="submit"
                          class="form-control-submit-button"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {};
</script>