<template>
  <div>
    <section class="default pb-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h1 class="h1-large">Produk</h1>
            <p class="p-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
      </div>
    </section>

    <section class="default pb-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <img class="img-fluid" src="assets/images/conventional.jpg"> 
          </div>
          <div class="col-lg-7">
            <h3>Conventional</h3>
            <p class="p-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p class="p-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <router-link to="/product/conventional" class="read-more no-line">Selengkapnya <span class="fas fa-long-arrow-alt-right"></span></router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="default pb-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h3>Hybrid</h3>
            <p class="p-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p class="p-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <router-link to="/product/hybrid" class="read-more no-line">Selengkapnya <span class="fas fa-long-arrow-alt-right"></span></router-link>
          </div>
          <div class="col-lg-5">
            <img class="img-fluid" src="assets/images/hybrid.jpg"> 
          </div>
        </div>
      </div>
    </section>

    <section class="default">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <img class="img-fluid" src="assets/images/blockwood.jpg"> 
          </div>
          <div class="col-lg-7">
            <h3>Modular Block Wood</h3>
            <p class="p-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p class="p-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <router-link to="/product/blockwood" class="read-more no-line">Selengkapnya <span class="fas fa-long-arrow-alt-right"></span></router-link>
          </div>
        </div>
      </div>
    </section>

    <div class="copyright bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <ul class="list-unstyled li-space-lg p-small">
              <li><a href="#">Faq</a></li>
              <li><a href="#">Terms & Conditions</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <p class="p-small statement">
              Copyright © <a href="#">Resident</a>
            </p>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <p class="p-small statement">
              Distributed by
              <a href="" target="_blank">Resident</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>