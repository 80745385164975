<template>
  <div>
    <section class="contactus">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-xl-5">
            <div class="text-container">
              <h1 class="h1-large">Kontak</h1>
              <p class="p-large">Hubungi kami jika ada pertanyaan terkait hal yang akan ditanyakan, silahkan isi form dibawah ini dan kami akan segera menanggapi anda.</p>

              <form action="" class="form-contactus">
                <div class="form-group">
                  <span class="form-label">Nama</span>
                  <input class="form-control" type="text">
                </div>

                <div class="form-group">
                  <span class="form-label">E-Mail</span>
                  <input class="form-control" type="text">
                </div>

                <div class="form-group">
                  <span class="form-label">Judul</span>
                  <input class="form-control" type="text">
                </div>

                <div class="form-group">
                  <span class="form-label">Deskripsi</span>
                  <textarea class="form-control" row="4"></textarea>
                </div>

                <div class="form-btn">
                  <button class="submit-btn">KIRIM <i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6 col-xl-7">
            <div class="image-container">
              <img
                class="img-fluid"
                src="assets/images/contactus.png"
                alt="alternative"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <div class="copyright bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <ul class="list-unstyled li-space-lg p-small">
              <li><a href="#">Faq</a></li>
              <li><a href="#">Terms & Conditions</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <p class="p-small statement">
              Copyright © <a href="#">Resident</a>
            </p>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <p class="p-small statement">
              Distributed by
              <a href="" target="_blank">Resident</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>