<template>
  <div>
    <section id="header" class="header">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-xl-5">
            <div class="text-container">
              <h1 class="h1-large">Rumah Premium Harga Terjangkau</h1>
              <p class="p-large">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                dignissim, neque ut ultrices sollicitudin
              </p>
              <router-link to="/product" class="btn-solid-lg"
                >Lihat Produk</router-link
              >
            </div>
          </div>
          <div class="col-lg-6 col-xl-7">
            <div class="image-container">
              <img
                class="img-fluid"
                src="assets/images/header.png"
                alt="alternative"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="default image-left">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-xl-6">
            <div class="image-container">
              <img
                class="img-fluid"
                src="assets/images/residence.png"
                alt="alternative"
              />
            </div>
          </div>

          <div class="col-lg-7 col-xl-6">
            <div class="text-container">
              <h1 class="h1-large">Produk Inovasi</h1>
              <p class="p-large">Rumah merupakan salah satu kebutuhan pokok bagi kehidupan. sejalan dengan pertambahan penduduk dan berkembangnya fasilitas pendukung di sekitar</p>
              <p class="p-large">Pilihan desain rumah yang dikonsepkan pada Cluster Teras Bali II ini menggunakan luas dan ukuran tipe mulai dari 24 m2, dengan desain fleksibel seperti sistem “lego”. Hal ini dimungkinkan karena sistem knockdown yang dapat didesain dan dirakit secara costumized untuk membentuk berbagai kebutuhan ruang rumah.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="default">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center mb-5">
            <h3>Planning & Construction</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="soon text-start mb-5">
              <div class="row">
                <div class="col-lg-12 mb-5">
                  <h4>Planning</h4>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
                <div class="col-lg-12 mb-5">
                  <h4>Preparation</h4>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="soon text-center mb-5">
              <div class="row">
                <div class="col-lg-12">
                  <img class="img-fluid" src="assets/images/planning.png"> 
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="soon text-end mb-5">
              <div class="row">
                <div class="col-lg-12 mb-5">
                  <h4>Construction</h4>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
                <div class="col-lg-12 mb-5">
                  <h4>Finishing</h4>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <div class="footer bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h4>Temukan Kami</h4>
            <div class="social-container">
              <span class="fa-stack">
                <a href="#your-link">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fab fa-facebook-f fa-stack-1x"></i>
                </a>
              </span>
              <span class="fa-stack">
                <a href="#your-link">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fab fa-twitter fa-stack-1x"></i>
                </a>
              </span>
              <span class="fa-stack">
                <a href="#your-link">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fab fa-pinterest-p fa-stack-1x"></i>
                </a>
              </span>
              <span class="fa-stack">
                <a href="#your-link">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fab fa-instagram fa-stack-1x"></i>
                </a>
              </span>
              <span class="fa-stack">
                <a href="#your-link">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fab fa-youtube fa-stack-1x"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <ul class="list-unstyled li-space-lg p-small">
              <li><a href="#">Faq</a></li>
              <li><a href="#">Terms & Conditions</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <p class="p-small statement">
              Copyright © <a href="#">Resident</a>
            </p>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <p class="p-small statement">
              Distributed by
              <a href="" target="_blank">Resident</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
   
  
  <script>
export default {
  name: "Home",
};
</script>
  